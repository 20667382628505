.content {
  font-family: PingFangSC-Regular;
  min-height: 100%;
}
.head {
  padding-top: 0.45rem;
  padding-top: calc(env(safe-area-inset-top) + 0.45rem);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.64rem;
  position: relative;
  left: 0;
  width: 100%;
  margin-bottom: 0.85rem;
}
.head::before {
  content: "";
  height: 3.16rem;
  height: calc(env(safe-area-inset-top) + 3.16rem);
  width: 100%;
  background: url(../../assets/imgs/new_color/my_head_bg.png) no-repeat center top;
  background-size: 100% 100%;
  display: inline-block;
  position: absolute;
  top: 0;
}
.head .head_left {
  z-index: 100;
  margin-left: 1.2rem;
  display: flex;
  align-items: center;
  margin-top: 0.85rem;
  margin-bottom: 0.26rem;
}
.head .head_left .head_center {
  margin-left: 0.36rem;
}
.head .head_left .head_center .phone {
  color: #333333;
  font-size: 0.28rem;
  font-weight: 500;
  text-align: left;
}
.head .head_left .head_center .lv_box {
  display: flex;
  align-items: center;
  margin-top: 2px;
}
.head .head_left .head_center .lv_box .vip {
  width: 0.9rem;
  height: 0.37rem;
  border-radius: 0.27rem;
  font-size: 0.24rem;
  text-align: center;
  line-height: 0.37rem;
  color: #D7B986;
}
.head .head_left .head_center .lv_box .representative {
  padding: 0 10px;
  margin-left: 8px;
}
.head .head_left .head_center .lv_box div {
  border-radius: 0.27rem;
  font-size: 0.24rem;
  height: 0.37rem;
  color: #D7B986;
  background: #6a5e4e;
  display: flex;
  justify-content: center;
  align-items: center;
}
.head .head_left .head_center .lv_box .notActive {
  background: #909399;
  color: #e8ecff;
}
.head .head_left .img {
  width: 1rem;
  height: 1rem;
}
.head .head_left .img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.head .head_right {
  margin-right: 0.6rem;
}
.head .head_right .icon img {
  width: 0.19rem;
  height: 0.32rem;
}
.K_VIP {
  margin: 0 0.2rem;
  border-radius: 0.16rem;
  padding: 0.05rem 0;
  background-image: linear-gradient(to left, #DDC399, #EAD7BA);
}
.K_VIP .main7 {
  padding: 0.06rem;
  margin: 0 0.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.K_VIP .main7 .section5 {
  display: flex;
  align-items: center;
}
.K_VIP .main7 .section5 img {
  width: 0.8rem;
  height: 0.64rem;
}
.K_VIP .main7 .section5 .word7 {
  color: #8A7048;
  font-size: 0.24rem;
}
.K_VIP .main7 .section6 {
  background-image: linear-gradient(to right, #C7A46F, #D8BA91);
  border-radius: 0.35rem;
  width: 1.69rem;
  height: 0.5rem;
  line-height: 0.45rem;
  text-align: center;
}
.K_VIP .main7 .section6 .word8 {
  font-size: 0.24rem;
  color: #8A7048;
}
.seation {
  margin: 0 0.2rem;
  margin-top: 0.2rem;
  border-radius: 0.16rem;
  height: 1.11rem;
  line-height: 1.11rem;
  background-color: #FFFFFF;
}
.seation .qb {
  margin: 0 0.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.seation .qb .qb_left {
  display: flex;
  align-items: center;
}
.seation .qb .qb_left img {
  width: 0.6rem;
  height: 0.6rem;
}
.seation .qb .qb_left .word7 {
  font-family: PingFangSC-Regular;
  margin-left: 0.12rem;
  color: #6F7989;
  font-size: 0.28rem;
}
.seation .qb .qb_right {
  display: flex;
  align-items: center;
}
.seation .qb .qb_right img {
  margin-left: 0.4rem;
  width: 0.14rem;
  height: 0.24rem;
}
.seation .qb .qb_right .word7 {
  font-family: PingFangSC-Medium;
  color: #333333;
  font-weight: bold;
  font-size: 0.32rem;
}
.seation1 {
  margin: 0 0.2rem;
  margin-top: 0.2rem;
  border-top-left-radius: 0.16rem;
  border-top-right-radius: 0.16rem;
  height: 1rem;
  line-height: 1rem;
  background-color: #FFFFFF;
}
.seation1 .qb {
  margin: 0 0.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.seation1 .qb .qb_left {
  display: flex;
  align-items: center;
}
.seation1 .qb .qb_left img {
  width: 0.6rem;
  height: 0.6rem;
}
.seation1 .qb .qb_left .word7 {
  font-family: PingFangSC-Regular;
  margin-left: 0.12rem;
  color: #6F7989;
  font-size: 0.28rem;
}
.seation1 .qb .qb_right {
  display: flex;
  align-items: center;
}
.seation1 .qb .qb_right img {
  margin-left: 0.4rem;
  width: 0.14rem;
  height: 0.24rem;
}
.seation1 .qb .qb_right .word7 {
  color: #333333;
  font-weight: 500;
  font-size: 0.32rem;
}
.seation2 {
  margin: 0 0.2rem;
  height: 1rem;
  line-height: 1rem;
  background-color: #FFFFFF;
}
.seation2 .qb {
  margin: 0 0.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.seation2 .qb .qb_left {
  display: flex;
  align-items: center;
}
.seation2 .qb .qb_left img {
  width: 0.6rem;
  height: 0.6rem;
}
.seation2 .qb .qb_left .word7 {
  font-family: PingFangSC-Regular;
  margin-left: 0.12rem;
  color: #6F7989;
  font-size: 0.28rem;
}
.seation2 .qb .qb_right {
  display: flex;
  align-items: center;
}
.seation2 .qb .qb_right img {
  margin-left: 0.4rem;
  width: 0.14rem;
  height: 0.24rem;
}
.seation2 .qb .qb_right .word7 {
  color: #333333;
  font-weight: 500;
  font-size: 0.32rem;
}
.seation3 {
  margin: 0 0.2rem;
  border-bottom-left-radius: 0.16rem;
  border-bottom-right-radius: 0.16rem;
  height: 1rem;
  line-height: 1rem;
  background-color: #FFFFFF;
}
.seation3 .qb {
  margin: 0 0.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.seation3 .qb .qb_left {
  display: flex;
  align-items: center;
}
.seation3 .qb .qb_left img {
  width: 0.6rem;
  height: 0.6rem;
}
.seation3 .qb .qb_left .word7 {
  margin-left: 0.12rem;
  color: #6F7989;
  font-size: 0.28rem;
}
.seation3 .qb .qb_right {
  display: flex;
  align-items: center;
}
.seation3 .qb .qb_right img {
  margin-left: 0.4rem;
  width: 0.14rem;
  height: 0.24rem;
}
.seation3 .qb .qb_right .word7 {
  color: #333333;
  font-weight: 500;
  font-size: 0.32rem;
}
.seation4 {
  margin: 0 0.2rem;
  margin-top: 0.2rem;
  border-top-left-radius: 0.16rem;
  border-top-right-radius: 0.16rem;
  height: 1rem;
  line-height: 1rem;
  background-color: #FFFFFF;
}
.seation4 .qb {
  margin: 0 0.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.seation4 .qb .qb_left {
  display: flex;
  align-items: center;
}
.seation4 .qb .qb_left img {
  width: 0.6rem;
  height: 0.6rem;
}
.seation4 .qb .qb_left .word7 {
  font-family: PingFangSC-Regular;
  margin-left: 0.12rem;
  color: #6F7989;
  font-size: 0.28rem;
}
.seation4 .qb .qb_right {
  display: flex;
  align-items: center;
}
.seation4 .qb .qb_right img {
  margin-left: 0.4rem;
  width: 0.14rem;
  height: 0.24rem;
}
.seation4 .qb .qb_right .word7 {
  color: #333333;
  font-weight: 500;
  font-size: 0.32rem;
}
.seation5 {
  margin: 0 0.2rem;
  margin-top: 0.2rem;
  height: 1rem;
  line-height: 1rem;
  background-color: #FFFFFF;
}
.seation5 .qb {
  margin: 0 0.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.seation5 .qb .qb_left {
  display: flex;
  align-items: center;
}
.seation5 .qb .qb_left img {
  width: 0.6rem;
  height: 0.6rem;
}
.seation5 .qb .qb_left .word7 {
  font-family: PingFangSC-Regular;
  margin-left: 0.12rem;
  color: #6F7989;
  font-size: 0.28rem;
}
.seation5 .qb .qb_right {
  display: flex;
  align-items: center;
}
.seation5 .qb .qb_right img {
  margin-left: 0.4rem;
  width: 0.14rem;
  height: 0.24rem;
}
.seation5 .qb .qb_right .word7 {
  color: #333333;
  font-weight: 500;
  font-size: 0.32rem;
}
.seation6 {
  margin: 0 0.2rem;
  height: 1rem;
  line-height: 1rem;
  background-color: #FFFFFF;
}
.seation6 .qb {
  margin: 0 0.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.seation6 .qb .qb_left {
  display: flex;
  align-items: center;
}
.seation6 .qb .qb_left img {
  width: 0.6rem;
  height: 0.6rem;
}
.seation6 .qb .qb_left .word7 {
  font-family: PingFangSC-Regular;
  margin-left: 0.12rem;
  color: #6F7989;
  font-size: 0.28rem;
}
.seation6 .qb .qb_right {
  display: flex;
  align-items: center;
}
.seation6 .qb .qb_right img {
  margin-left: 0.4rem;
  width: 0.14rem;
  height: 0.24rem;
}
.seation6 .qb .qb_right .word7 {
  color: #333333;
  font-weight: 500;
  font-size: 0.32rem;
}
.seation7 {
  margin: 0 0.2rem;
  height: 1rem;
  line-height: 1rem;
  background-color: #FFFFFF;
  border-bottom-left-radius: 0.16rem;
  border-bottom-right-radius: 0.16rem;
}
.seation7 .qb {
  margin: 0 0.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.seation7 .qb .qb_left {
  display: flex;
  align-items: center;
}
.seation7 .qb .qb_left img {
  width: 0.6rem;
  height: 0.6rem;
}
.seation7 .qb .qb_left .word7 {
  font-family: PingFangSC-Regular;
  margin-left: 0.12rem;
  color: #6F7989;
  font-size: 0.28rem;
}
.seation7 .qb .qb_right {
  display: flex;
  align-items: center;
}
.seation7 .qb .qb_right img {
  margin-left: 0.4rem;
  width: 0.14rem;
  height: 0.24rem;
}
.seation7 .qb .qb_right .word7 {
  color: #333333;
  font-weight: 500;
  font-size: 0.32rem;
}
.login_out {
  margin: 0 0.2rem;
  margin-top: 0.2rem;
  border-radius: 0.16rem;
  height: 1rem;
  line-height: 1rem;
  text-align: center;
  color: #EFBA0D;
  background-color: #FFFFFF;
  font-weight: bold;
  font-size: 0.32rem;
}
.kong {
  width: 100%;
  height: 1.6rem;
}
.tab_bottom {
  display: flex;
  justify-content: space-between;
  position: fixed;
  left: 0;
  bottom: 0;
  height: 1.14rem;
  width: 100%;
  background-color: #ffffff;
  border-top: 0.005rem solid #dedede;
}
.tab_bottom .tab_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.14rem 0.8rem;
}
.tab_bottom .tab_item .logo {
  width: 0.4rem;
  height: 0.4rem;
}
.tab_bottom .tab_item .logo img {
  width: 100%;
  height: 100%;
}
.tab_bottom .tab_item .logo1 {
  width: 0.32rem;
  height: 0.4rem;
}
.tab_bottom .tab_item .logo1 img {
  width: 100%;
  height: 100%;
}
.tab_bottom .tab_item .txt {
  color: #acb5bc;
  margin-top: 0.04rem;
  font-size: 0.24rem;
  transform: scale(0.916);
}
.tab_bottom .tab_item .active {
  color: #efba0d;
  font-family: PingFangSC-Medium;
}
.van-nav-bar__content {
  height: 1.6rem !important;
}
